import React from "react"
import {
  DiscussBox,
  ToSeeBox,
  WhoWasBox,
  ActivityBox,
  CopyrightBox,
  DefinitionBox
} from "../../../components/boxes"
import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import lanfranc from "./lanfranc.jpg"
import lanfranc2 from "./lanfranc-2x.jpg"
import worksheets from "../worksheets.svg"
import { withPrefix } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

const Lanfranc = () => (
  <Layout title="Archbishop Lanfranc">
    <SmallSectionHeader
      color={theme.colors.purple.dark}
      image={headerImage}
      to="/a-walk-through-time"
    >
      A walk through time
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/a-walk-through-time', name: 'A walk through time' },
      { name: 'Archbishop Lanfranc' }
    ]} />
    <Page>
      <h1>Archbishop Lanfranc</h1>
      <p>Following the Norman conquest of England in 1066, the new king William the Conqueror installed a new Archbishop at Canterbury Cathedral. Archbishop Lanfranc began a big building project, rebuilding the Cathedral in the Norman style as a place of worship for a community of around 70 monks. Caen stone was brought by sea from Normandy and up the River Stour. The works took seven years to complete. A fire in 1174 destroyed much of Lanfranc’s work.</p>

      <hr />

      <h2>William, Duke of Normandy</h2>
      <p className="page-left">
        When the English King Edward died without leaving a male heir (the person who gets the title and property of another on their death) the English nobles nominated Harold Godwinson as King of England. However, two other men thought they had a claim to the throne. One of them was Harold Hardrada and the other was William, duke of Normandy, who believed Edward had nominated him as the successor. In 1066 he invaded and took the throne after he killed Harold at the Battle of Hastings. Duke William, became known as William the Conqueror. William marched his army to Canterbury on his way to London to be coronated (formally crowned king). The city surrendered and William ordered a castle to be built to control the population. His son, who was also called William, later had the castle rebuilt in stone.
      </p>
      <Figure
        caption="Norman soldiers fighting as depicted in the Tapestry of Bayeaux"
        className="page-right"
      >
        <StaticImage
          src="./hastings.jpg"
          alt="Bayeaux Tapestry Fragment"
        />
      </Figure>

      <DefinitionBox title="Heir" className="page-left">
        The person who gets the title and property of another when this person dies.
      </DefinitionBox>
      <DefinitionBox title="Coronation" className="page-right">
        To be formally crowned king.
      </DefinitionBox>

      <DiscussBox>
        Imagine you are living in Canterbury when the conquering Norman army marches on the city, how do you feel?
      </DiscussBox>
      <DiscussBox>
        Some people in Canterbury had Norman trade links or had lived in Normandy and there were also some Normans in living in the City already. How might these people with Norman connections have been treated by the people of Canterbury and by the invading Normans?
      </DiscussBox>

      <WhoWasBox
        name="Archbishop Lanfranc"
        dates="b. about 1005 – d. 1089"
        image={{ src: lanfranc, srcSet: `${lanfranc} 244w, ${lanfranc2} 488w` }}
      >
        William the Conqueror brought many followers from Normandy and put them in important positions in England to help him keep control. Lanfranc was the prior (the second-in-charge of a monastery after the abbot) of a Norman monastery at Bec. Before becoming a monk he had been a lawyer. As Archbishop of Canterbury he sought to maintain independence from the Pope in Rome. The Pope was seen as the supreme authority in the Catholic Church. Lanfranc held control of the Church in England by replacing many Anglo-Saxon bishops with Normans.
      </WhoWasBox>

      <p>
        Some of our evidence for the Norman invasion comes from a giant embroidery called the Bayeux Tapestry which depicts the Battle of Hastings. Some people think it might have been made in Canterbury shortly after the Normans arrived here.
      </p>
      <DiscussBox>
        What different ways did William the Conqueror use to keep power in England?
      </DiscussBox>
      <h2>The monastery at Canterbury Cathedral</h2>
      <p>
        The monks who lived in the monastery at Canterbury Cathedral followed some strict rules laid out in a book called the Rule of Saint Benedict. They met every day in the Chapter House to chant one of the 73 chapters of the book.
      </p>
      <p>
        Saint Benedict had developed this way of living in around the year 500. The Rule told the monks to live simply and to work for God, to welcome strangers and give food to the poor.
      </p>
      <p className="page-left">
        Daily life for the monks was based around eight services of prayer which took place in the Cathedral Quire. During these services, they would sing psalms (sacred songs), listen to Bible readings and say prayers.
      </p>
      <Figure
        className="page-right row-3"
      >
        <StaticImage
          src='./monk.png'
          alt="Monk"
        />
      </Figure>
      <p className="page-left">The monks lived together in shared buildings. They slept in the dormitory, warmed themselves in the common room and visited the necessarium or communal toilets which contained 55 seats.</p>
      <p className="page-left">
      Some monks had specific jobs within the monastery, such as the cellarer who managed the community’s food and drink and the infirmarian who looked after those who were sick or elderly. Other monks were scribes (writers) and spend a lot of time writing and decorating books and manuscripts by hand. These monks were experts in their fields and the work they produced was quite beautiful and therefore in great demand all over the country. They worked in the Cloisters, which is where the Novices (apprentice monks) had their lessons too. It must have been very chilly there in the winter!
      </p>

      <p className="page-left">
      The monks ate together in the refectory and were given two meals a day. All the food was prepared on site. There was a brewery for making beer, a bakery for bread and a kitchen that fed the whole community. The monks were not allowed to speak at meal times, so instead they developed their own special sign language.
      </p>

      <ToSeeBox
        items={[
          {
            location: "Quire",
            items: [
              {
                title: "The Quire",
                content:
                  "The heart of the Cathedral and place the monks carried out their daily offices.",
              },
            ],
          },
          {
            location: "Great Cloister",
            items: [
              {
                title: "Western Wall of the Cloister",
                content:
                  "The Cloister was a place of conversation which linked the various parts of the monastery. The monks who studied here sat at stone benches called carrels. The Western Wall of the Cloister retains medieval building material.",
              },
            ],
          },
          {
            location: "Chapter House",
            items: [
              {
                title: "The Chapter House",
                content:
                  "The largest Chapter House in England. The monks met here daily to listen to readings from the Bible and the Rule of Saint Benedict and to discuss the day-to-day running of the monastery.",
              },
            ],
          },
          {
            location: "Water Tower",
            items: [
              {
                title: "Water Tower",
                content:
                  "Although added after Lanfranc’s time by Prior Wibert, the existence of the Water Tower shows how important cleanliness was to the Rule of Saint Benedict and the monk’s daily lives.",
              },
            ],
          },
          {
            location: "Cathedral Archive",
            items: [
              {
                title: "The Accord of Winchester",
                content:
                  "This is an agreement between the Archbishop Thomas of York and Archbishop Lanfranc of Canterbury signed in 1072. It established that the Archbishop of Canterbury had ‘primacy’ over the Archbishop of York and put Lanfranc in ultimate control of the Church in England.",
              },
            ],
          },
        ]}
      />

      <h2>Activities</h2>
      <ActivityBox
        title="A Walk Through Time activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/AWalkThroughTime.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘A walk through time’ resource.
      </ActivityBox>

      <WhereNext
        items={[
          {
            name: "Archbishop Thomas Becket",
            to: "/a-walk-through-time/becket",
          },
          { name: "The Reformation in England", to: "/a-walk-through-time/reformation" },
          { name: "World War Two", to: "/a-walk-through-time/ww2" },
        ]}
      />

      <hr />

      <CopyrightBox />
    </Page>
  </Layout>
)

export default Lanfranc